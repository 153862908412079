import autoTable from "jspdf-autotable";
import { layoutConfig, printSRFSConfigData, printSRFSConfiguration } from "./SRFSConstant";
import {
  customFontFamily,
  dynamicHeader,
  footerConfig,
  headerConfig,
  tableConfigurationheaderandfooter,
} from "./srfsConfig";
import { drawText } from "./commonTable";


const certificateHeader = "/images/srfheader.png";
const certificateFooter = "/images/certificate_footer.png";


export const drawLetterHeadHeaderImg = () => {
  let headerImage = certificateHeader;
  try {
    if (printSRFSConfigData.letterHeadAndElectronicAndDraftConfig?.letterHead) {
      const scaledWidth = printSRFSConfiguration.pageWidth;
      const scaledHeight = headerConfig.lettterImgHeight;
      printSRFSConfiguration.pdfDoc.addImage(
        headerImage,
        "PNG",
        0,
        0,
        scaledWidth,
        scaledHeight
      );
    }
  } catch (error) {
    console.error("Error drawing header image:", error);
  }
};
export const drawLetterFooterImg = () => {
  try {
    if (printSRFSConfigData.letterHeadAndElectronicAndDraftConfig?.letterHead && footerConfig?.footerimgRemove) {
      const scaledWidth = printSRFSConfiguration.pageWidth;
      // Draw the image on the page
      printSRFSConfiguration.pdfDoc.addImage(
        certificateFooter,
        "PNG",
        0,
        printSRFSConfiguration.pageHeight - footerConfig.footerimgHeight + 2,
        scaledWidth,
        footerConfig.footerimgHeight
      );
    }
  } catch (error) {
    console.error("Error drawing footer image:", error);
  }
};

export const drawDynamicHeader = () => {
  let localY = headerConfig?.lettterImgHeight + 5;

  const panels = dynamicHeader(
    printSRFSConfigData.SRFSDetailsConfig
  );
  let tableRows = [];
  for (let i = 0; i < panels.leftPanel.keys.length; i++) {
    tableRows.push([
      {
        content: panels.leftPanel.keys[i],
        styles: { halign: "left", font: customFontFamily?.boldFont },
      },
      { content: panels.leftPanel.values[i], styles: { halign: "left" } },
      {
        content: panels.rightPanel.keys[i],
        styles: { halign: "left", font: customFontFamily?.boldFont },
      },
      { content: panels.rightPanel.values[i], styles: { halign: "left" } },
    ]);
  }
  // Draw the table using autoTable
  autoTable(printSRFSConfiguration.pdfDoc, {
    startY: localY,
    margin: tableConfigurationheaderandfooter?.margins,
    body: tableRows,
    theme: "grid",
    headStyles: {
      fillColor: false,
      textColor: printSRFSConfiguration.textColor,
      fontSize: headerConfig.tableRowfontSizes,
      font: customFontFamily?.boldFont,
    },
    styles: {
      fillColor: false,
      textColor: printSRFSConfiguration.textColor,
      lineColor: printSRFSConfiguration.textColor,
      lineWidth: printSRFSConfiguration.lineWidth,
      fontSize: headerConfig.tableRowfontSizes,
      halign: "center",
      cellPadding: headerConfig.cellPadding,
      font: customFontFamily?.normalFont,
    },
  });
};

export const drawDynamicFooter = () => {
  // if(!printSRFSConfigData.IsCSRF) return;
  let srfsSetting =
    printSRFSConfigData.letterHeadAndElectronicAndDraftConfig;

  let Row1 = ["Customer Representative Name", "Sign","RCTL Representative Name","Sign"];
  let Row2 = [
    // printSRFSConfigData.SRFSDetailsConfig.userId?.createdBy,
    // printSRFSConfigData.SRFSDetailsConfig.userId?.approvedBy,
    "",
    "",
    "Technical Manager",
    ""
  ];
  let Row3 = [
    "",
    "",
    "",
    // printSRFSConfigData.SRFSDetailsConfig.userImg?.createdByImg,
    printSRFSConfigData.SRFSDetailsConfig.userImg?.approvedByImg,
  ];

  const tableRows = [];
  // Add Row1 to tableRows
  tableRows.push(
    Row1.map((content) => ({
      content,
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        halign: "center",
      },
    }))
  );

  // Add Row2 to tableRows
  tableRows.push(
    Row2.map((content) => ({
      content,
      styles: {
        font: customFontFamily?.normalFont,
        halign: "center",
      },
    }))
  );

  // Add Row3 with images (if present)
  // if (srfsSetting?.signImg) {
  //   tableRows.push(
  //     Row3.map((imgSrc) => ({
  //       content: "", // Content remains empty; images will be added later
  //       imgSrc, // Pass the image source for use in didDrawCell
  //       styles: {
  //         font: customFontFamily?.normalFont,
  //         halign: "center",
  //         minCellHeight: 25,
  //       },
  //     }))
  //   );
  // }

  let formattedTableRows = tableRows.map((row, rowIndex) =>
    row.map((column, columnIndex) => ({
      ...column, // Keep all other properties of the column
      styles: {
        ...column.styles, // Preserve existing styles
        halign:
          columnIndex === 0 // First column
            ? "center"
            : columnIndex === row.length - 1 // Last column
            ? "center"
            : "center", // Default alignment for other cases
      },
    }))
  );

   // Calculate the total available width of the table
      const totalPageWidth =
      printSRFSConfiguration.pageWidth -
      tableConfigurationheaderandfooter?.margins.right * 2;
      const columnPercentages = [30, 20, 30, 20];
      // Calculate the actual column widths based on percentages
      const columnWidths = columnPercentages.map((percentage) => {
        return (totalPageWidth * percentage) / 100;
      });

  // Generate table with autoTable
  autoTable(printSRFSConfiguration.pdfDoc, {
    startY:
      printSRFSConfiguration.pageHeight - footerConfig.footerimgHeight - footerConfig.footerandbodygap -25,
    margin: tableConfigurationheaderandfooter?.margins,
    body: formattedTableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: printSRFSConfiguration.textColor,
      lineColor: printSRFSConfiguration.textColor,
      lineWidth: printSRFSConfiguration.lineWidth,
      fontSize: printSRFSConfiguration.fontSizes.h2,
      halign: "center",
      font: customFontFamily?.normalFont,
      cellPadding: srfsSetting?.signImg ? { top: 5, left: 5, bottom: 5, right: 5 } : { top: 5, left: 5, bottom: 5, right: 5 },
    },
    columnStyles: {
      // Set equal width for each column
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1] },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
    },
    // didDrawCell: (data) => {
    //   const { section } = data;
    //   if (section === "body" && srfsSetting?.signImg) {
    //     const cell = data.cell;
    //     const rowIndex = data.row.index;
    //     const columnIndex = data.column.index;
    //     // Check if this cell corresponds to the imageRow
    //     if (rowIndex === tableRows.length - 1) {
    //       const imgSrc = Row3[columnIndex];
    //       if (imgSrc) {
    //         const imgWidth = 60;
    //         const imgHeight = cell.height - 10;
    //         const x = cell.x + 5;
    //         const y = cell.y + 5;
    //         // Add the image to the PDF
    //         printSRFSConfiguration.pdfDoc.addImage(
    //           imgSrc,
    //           "PNG",
    //           x,
    //           y,
    //           imgWidth,
    //           imgHeight
    //         );
    //       }
    //     }
    //   }
    // },
  });

  drawText(
    `Format No : - FR-QM-05 Issue No. : 01 , Issue Date:01-01-2024 ,Revision No. : 00 Revision Date :--`,
      layoutConfig.xPositionGlobal - 10,
      printSRFSConfiguration.pageHeight -
        footerConfig.footerimgHeight - 10,
      "left",
      printSRFSConfiguration.fontSizes.h6,
      2,
      0,
      "normalfont"
    );

};
