import autoTable from "jspdf-autotable";
import { customFontFamily, footerConfig, tableConfiguration, totalHeaderHeightWithCalibrationLableorNot } from "./srfsConfig";
import { layoutConfig, printSRFSConfigData, printSRFSConfiguration } from "./SRFSConstant";
import { addNewPage } from "./print";

export const drawText = (
  text,
  xSize,
  ySize,
  align = "left",
  fontSize = 10,
  topSpace = 0,
  bottomSpace = 0,
  fontType
) => {
  printSRFSConfiguration.pdfDoc.setFontSize(fontSize);
  if (fontType == "normalfont") {
    printSRFSConfiguration.pdfDoc.setFont(
      customFontFamily?.normalFont,
      customFontFamily?.normal
    );
  } else {
    printSRFSConfiguration.pdfDoc.setFont(
      customFontFamily?.boldFont,
      customFontFamily?.bold
    );
  }

  let yPos = ySize + topSpace;

  // Align the text horizontally
  let textWidth = printSRFSConfiguration.pdfDoc.getTextWidth(text);
  let xPos = xSize;

  if (align === "left") {
    xPos += 1;
  } else if (align === "center") {
    xPos =
      (printSRFSConfiguration.pdfDoc.internal.pageSize.width - textWidth) / 2;
  } else if (align === "right") {
    xPos =
      printSRFSConfiguration.pdfDoc.internal.pageSize.width - textWidth - xSize;
  }

  printSRFSConfiguration.pdfDoc.text(text, xPos, yPos);
  layoutConfig.yPositionGlobal = yPos + fontSize + bottomSpace;

  if (fontType == "normalfont") {
    printSRFSConfiguration.pdfDoc.setFont(
      customFontFamily?.boldFont,
      customFontFamily?.bold
    );
  } else {
    printSRFSConfiguration.pdfDoc.setFont(
      customFontFamily?.normalFont,
      customFontFamily?.normal
    );
  }
};

export const drawBox = async (panels) => {
  let tableRows = [];
  for (let i = 0; i < panels.leftPanel.keys.length; i++) {
    tableRows.push([
      {
        content: panels.leftPanel.keys[i],
        styles: { halign: "left", font: customFontFamily?.boldFont },
      },
      { content: panels.leftPanel.values[i], styles: { halign: "left" } },
      {
        content: panels.rightPanel.keys[i],
        styles: { halign: "left", font: customFontFamily?.boldFont },
      },
      { content: panels.rightPanel.values[i], styles: { halign: "left" } },
    ]);
  }

  // Calculate the total available width of the table
  const totalPageWidth =
    printSRFSConfiguration.pageWidth - tableConfiguration?.margins.right * 2;
  const columnPercentages = [25, 25, 25, 25];
  // Calculate the actual column widths based on percentages
  const columnWidths = columnPercentages.map((percentage) => {
    return (totalPageWidth * percentage) / 100;
  });

  autoTable(printSRFSConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal,
    margin: tableConfiguration?.margins,
    body: tableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      fontSize:
        panels.tableTextfontSize || printSRFSConfiguration.fontSizes.hten,
      font: customFontFamily?.normalFont,
      cellPadding:
        panels.cellPadding || printSRFSConfiguration.cellPaddingTable,
      valign: "middle",
    },
    columnStyles: {
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1] },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
    },
  });

  layoutConfig.yPositionGlobal =
    printSRFSConfiguration.pdfDoc.lastAutoTable.finalY + 10;
};

export const drawfirst2ColumnRowspan = async (panels) => {
  let tableRows = [];
  const rowSpan = panels?.rightPanel?.keys?.length;

  let row = [
    {
      content: panels?.leftPanel.keys[0],
      rowSpan: rowSpan,
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        valign: "middle",
        halign: "left",
      },
    },
    {
      content: panels?.leftPanel.values[0],
      rowSpan: rowSpan,
      styles: { valign: "middle", halign: "left" },
    },
    {
      content: panels?.rightPanel.keys[0],
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        valign: "middle",
        halign: "left",
      },
    },
    {
      content: panels?.rightPanel.values[0],
      styles: { valign: "middle", halign: "left" },
    },
  ];
  tableRows.push(row);

  for (let i = 1; i < panels?.rightPanel.keys.length; i++) {
    let row = [
      {
        content: panels?.rightPanel.keys[i],
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          valign: "middle",
          halign: "left",
        },
      },
      {
        content: panels?.rightPanel.values[i],
        styles: { valign: "middle", halign: "left" },
      },
    ];
    tableRows.push(row);
  }

  // Add the final row with Address (colSpan 3)
  let addressRow = [
    {
      content: panels?.leftPanel.keys[1], // "Client" key, to keep the layout
      styles: {
        font: customFontFamily?.boldFont,
        fontStyle: customFontFamily?.bold,
        valign: "middle",
        halign: "left",
      },
    },
    {
      content: panels?.leftPanel.values[1], // Address content
      colSpan: 3, // This will span across the last 3 columns
      styles: { valign: "middle", halign: "left" },
    },
  ];

  tableRows.push(addressRow);

  // Calculate the total available width of the table
  const totalPageWidth =
    printSRFSConfiguration.pageWidth - tableConfiguration?.margins.right * 2;
  const columnPercentages = [20, 35, 20, 25];

  // Calculate the actual column widths based on percentages
  const columnWidths = columnPercentages.map((percentage) => {
    return (totalPageWidth * percentage) / 100;
  });

  // Render the table using autoTable
  autoTable(printSRFSConfiguration.pdfDoc, {
    startY: layoutConfig.yPositionGlobal,
    margin: tableConfiguration?.margins,
    body: tableRows,
    theme: "grid",
    styles: {
      fillColor: false,
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      fontSize: printSRFSConfiguration.fontSizes.hten,
      font: customFontFamily?.normalFont,
      cellPadding:
        panels.cellPadding || printSRFSConfiguration.cellPaddingTable,
      halign: "middle",
    },
    columnStyles: {
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1] },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
    },
  });

  layoutConfig.yPositionGlobal =
    printSRFSConfiguration.pdfDoc.lastAutoTable.finalY + 0;
};

export const noteBox = async (setPageSection) => {
  await setPageSection();
  const pageWidth =
    printSRFSConfiguration.pdfDoc.internal.pageSize.width;
  const pageHeight =
    printSRFSConfiguration.pdfDoc.internal.pageSize.height;
  let remarksWidth = (pageWidth * 93) / 100;
  
  // Calculate text height
  const calculateRemarksTextHeight = (remarks, width, fontSize) => {
    let totalHeight = 0;
    remarks.forEach((line) => {
      const wrappedText = printSRFSConfiguration.pdfDoc.splitTextToSize(
        line,
        width
      );
      totalHeight += wrappedText.length * fontSize * 1.2;
    });
    return totalHeight;
  };

  // Prepare remarks text
  const remarksText = [`1. All the terms & Conditions agreed as per Our Quotation and Your PO No- ${printSRFSConfigData.SRFSDetailsConfig?.poNumber} \n2. Above customer information will be entered into final certificates and no changes will be entertained later.\n3. Delivery: Billed customer to kindly collect duly calibrated instruments in 3 working days\n4. Payment: Customer to kindly release the payment within 7 working days from the date of Invoice. Payment through Cheque in favor of “RECON CALIBRATION AND TEST LABS LLP.”\n5. Calibration Certificates: Lab undertakes to issue final certificates within 24 hours of receipt of payment.\n6. Conformity will be given based on above input. If the detail of Reference standard / customer specification is not given, then conformity statement will not be given in the certificate.\n7.Satisfactory calibration report in no ways implies that the equipment calibrated is approved by NABL.`];
  
  // Calculate remarks section height
  const remarksLabelHeight = printSRFSConfiguration.fontSizes.h4 * 1.2;
  const remarksTextHeight = calculateRemarksTextHeight(
    remarksText,
    remarksWidth,
    printSRFSConfiguration.fontSizes.h4
  );
  const totalRemarksHeight = remarksLabelHeight + remarksTextHeight;
  // console.log(layoutConfig.yPositionGlobal, totalRemarksHeight);
  // console.log(
  //   pageHeight -
  //     footerConfig.footerimgHeight -
  //     footerConfig.footertableHeight -
  //     footerConfig.footerandbodygap
  // );
  // Check if a new page is needed
  if (
    layoutConfig.yPositionGlobal + totalRemarksHeight >
    pageHeight -
      footerConfig.footerimgHeight -
      footerConfig.footertableHeight -
      footerConfig.footerandbodygap - 10
  ) {
    await addNewPage();
    layoutConfig.yPositionGlobal =
    totalHeaderHeightWithCalibrationLableorNot.total + 5;
  }

  // Draw "Remarks:" label
  drawText(
    "Terms & Conditions:",
    layoutConfig.xPositionGlobal,
    layoutConfig.yPositionGlobal,
    "left",
    printSRFSConfiguration.fontSizes.h2,
    5,
    0
  );


  layoutConfig.yPositionGlobal += remarksLabelHeight -10; 

  // Draw remarks text with wrapping
  const xPositionRemarks = layoutConfig.xPositionGlobal;
  remarksText.forEach((line) => {
    const wrappedText = printSRFSConfiguration.pdfDoc.splitTextToSize(
      line?.trim(),
      remarksWidth 
    );
    wrappedText.forEach((wrappedLine) => {
      drawText(
        wrappedLine,
        xPositionRemarks,
        layoutConfig.yPositionGlobal,
        "left",
        printSRFSConfiguration.fontSizes.h3,
        0,
        0,
        "normalfont"
      );
      layoutConfig.yPositionGlobal += 2; // Increment position for the next line
    });
  });

   layoutConfig.yPositionGlobal += 5; // Final adjustment
};